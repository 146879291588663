<template>
  <ul class="treeview-menu">
    <li
      v-for="(item, index) in options"
      v-bind:key="index"
      v-bind:class="{
        disabled: item.disabled,
        hidden: item.hidden,
        divisor: item.target == '-',
        treeview: item.options && item.options.length
      }"
    >
      <MenuItemLink
        :data-testid="item.label.toLowerCase().replace(/ /g, '-')"
        v-if="item.type == 'link' && item.target != '-'"
        :option="item"
        :multilevel="item.options && item.options.length ? true : false"
        :subtree="true"
        @toggleTreeview="toggleTreeview(item.id)"
      ></MenuItemLink>
      <MenuItemPanel
        :data-testid="item.label.toLowerCase().replace(/ /g, '-')"
        v-else-if="item.target != '-'"
        :option="item"
        :multilevel="item.options && item.options.length ? true : false"
        :subtree="true"
        :equipmentId="equipmentId"
        @toggleTreeview="toggleTreeview(item.id)"
      ></MenuItemPanel>
      <MenuTreeView
        v-if="item.options && item.options.length ? true : false"
        :items="item.options"
        :id="item.id"
        :equipmentId="equipmentId"
      />
    </li>
  </ul>
</template>

<script>
import MenuItemLink from "./menu-item-link";
import MenuItemPanel from "./menu-item-panel";

export default {
  name: "MenuTreeView",
  components: {
    MenuItemLink,
    MenuItemPanel
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    equipmentId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    options() {
      var self = this;
      var items = JSON.parse(JSON.stringify(self.items));
      var lst = items
        .map(function(i) {
          i.requireEquipment = (i.target || "").indexOf("equipment_id") > 0;
          i.href = i.requireEquipment
            ? i.target.replace(/\:?equipment_id/, self.equipmentId)
            : i.target || ".";
          i.type = i.href.indexOf("/dashboard") === 0 ? "panel" : "link";
          i.id = "option-" + Math.floor(Math.random() * 100000);
          return i;
        })
        .filter(function(i) {
          if (i.rule && !self.$can("manage", i.rule)) {
            return false;
          }
          if ("requireEquipment" in i && i.requireEquipment) {
            return self.equipmentId != 0;
          }
          return true;
        });
      return lst;
    }
  },
  methods: {
    toggleTreeview(id) {
      $(`#${id}`).slideToggle(300);
    }
  }
};
</script>

<style></style>
