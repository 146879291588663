export const ExtFunctions = {
  onlinee: (vm /*,data*/) => {
    if (vm && vm?.$root?.config?.api_url) {
      const elId = "onlinee_login_form";
      let f = document.getElementById(elId);
      if (!f) {
        let url = '';
        
        if (document.location.hostname.startsWith("app.")) {
          url = `/onlinee/jwt_login/`;
        } else {
          let api_url = vm.$root.config.api_url.split("/");
          api_url.splice(3, 2);
          api_url = api_url.join("/");
          url = `${api_url}/onlinee/jwt_login/`;
        }

        let token = vm.$cookies.get("access_token");
        f = document.createElement("FORM");
        f.id = elId;
        f.target = "_blank";
        f.action = `${url}`;
        f.method = "POST";
        f.innerHTML = `<input type='hidden' name='token' value='${token}'/>`;
        document.body.appendChild(f);
      }
      f.submit();
    }
  }
};
